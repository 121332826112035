<template>
  <div class="home">
    <div class="columns is-multiline is-mobile">
      <div class="column is-4-tablet is-6-mobile is-3-desktop"
           v-for="item in cardLinks"
           :key="item.name">
        <home-cards-item
            v-if="($alfalab.allowOnlyLapi($auth.user(), item))"
            prefix="reports"
            :data="item"
        ></home-cards-item>
      </div>
      <div class="column is-4-tablet is-6-mobile is-3-desktop"
           v-if="($alfalab.enableMonitorLaboratory($auth))">
      <home-cards-item
          prefix="reports"
          :data="{name: 'task-monitor', icon: 'notes-medical', color: 'is-yellow', route: {name: 'task-monitor'}, exact: false, onlySide: false, onlyLapi: false}"
      ></home-cards-item>
      </div>
      <div class="column is-4-tablet is-6-mobile is-3-desktop"
           v-if="($alfalab.enableMonitorXray($auth))">
      <home-cards-item
          prefix="reports"
          :data="{name: 'task-monitor-xray', icon: 'notes-medical', color: 'is-purple', route: {name: 'task-monitor-xray'}, exact: false, onlySide: false, onlyLapi: false}"
      ></home-cards-item>
      </div>
      <div class="column is-4-tablet is-6-mobile is-3-desktop">
        <home-cards-item
            prefix="reports"
            :data="{name: 'orders-report', icon: 'file-alt', color: 'is-mexican-pink', route: {name: 'orders-report'}, exact: false, onlySide: false, onlyLapi: false}"
        ></home-cards-item>
      </div>
      <div class="column is-4-tablet is-6-mobile is-3-desktop">
        <home-cards-item
            prefix="reports"
            :data="{name: 'physical-results', icon: 'file-alt', color: 'is-mexican-pink', route: {name: 'physical-results'}, exact: false, onlySide: false, onlyLapi: false}"
        ></home-cards-item>
      </div>
    </div>
  </div>
</template>

<script>
import HomeCardsItem from '../../components/dashboard/HomeCardsItem'
export default {
  name: "Index",
  components: {HomeCardsItem},
  computed: {
    cardLinks() {
      return this.$store.state.reports.links || []
    }
  }
}
</script>

<style scoped>

</style>
